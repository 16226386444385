.Size-Up-section2 {
  opacity: 0;
  font-size: 4.8vw;
  margin-left: 0vw;
  line-height: 5.5vw;
}

.Size-Up-section2is-Size-Up2 {
  opacity: 1;
  font-size: 4.8vw;
  margin-left: 0;
}

.is-Size-Up2 {
  opacity: 1;
  font-size: 4.8vw;
  margin-left: 0;
}
