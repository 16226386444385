.headerWrap {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-gap: 20px;
  height: 100svh;
  min-height: 800px;
  width: 100%;
}

/*
  치지직 거리는 애니메이션 효과
.headerWrap:after {
  content: "";
  background-image: url("/src/assets/images/filmgrain.png");
  height: 300%;
  width: 300%;
  position: fixed;
  opacity: 0.05;
  animation: animateGrain 8s steps(10) infinite;
} */

@keyframes animateGrain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, -20%);
  }
  30% {
    transform: translate(-5%, -10%);
  }
  40% {
    transform: translate(-15%, -20%);
  }

  50% {
    transform: translate(-5%, -10%);
  }
  60% {
    transform: translate(-15%, -20%);
  }
  70% {
    transform: translate(-5%, -10%);
  }
  80% {
    transform: translate(-15%, -20%);
  }
  90% {
    transform: translate(-5%, -10%);
  }
  100% {
    transform: translate(-15%, -20%);
  }
}

.headerMain {
  width: 100%;
  height: 100svh;
  border-right: 1px solid #04260c66;
  font-family: "Typewcond";
  font-size: max(30px, 2vw);
  color: #04260c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerMainFont {
  transform: rotate(270deg);
  position: absolute;
  letter-spacing: 2px;
}

#grainy {
  filter: url("#grainy");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
}

#grainyEffect {
  filter: url(#grainy);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
}

@media (max-width: 849px) {
  .headerMain {
    display: none;
  }
  .headerWrap {
    position: fixed;
    display: grid;
    grid-template-columns: 12fr;
    grid-gap: 20px;
    height: 100svh;
    min-height: 800px;
    width: 100%;
  }
}
