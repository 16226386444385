.mainCallToAction {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  height: 90svh;
  min-height: 800px;
  width: 100%;
  color: #04260c;
  position: relative;
  z-index: 1;
  margin-top: 10svh;
}

.mainCallToActionBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainCallToActionTitle {
  font-size: max(90px, 10.5vw);
  padding-top: 40px;
  font-family: "gravesend-sans";
  font-weight: 700;
  transform: scaleY(1.2);
  padding-left: 4px;
  letter-spacing: -0.055em;
  height: 16.75vw;
}

.mainCallToActionTextArea {
  padding-top: 16px;
  font-size: 20px;
  font-family: "Typewcond";
  padding-left: 0px;
  font-weight: 400;
  line-height: 120%;
  opacity: 0.8;
  letter-spacing: -0.5px;
}

.mainCallToActionTextArea > div:first-child {
  margin-bottom: 5px;
}
.mainCallToActionImgArea {
  margin-top: 30px;
}

.mainCallToActionImgArea > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 250px;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  margin-top: 8px;
  opacity: 0.8;
  transition: all 0.3s ease;
  cursor: pointer;
}
.mainCallToActionImgAreaWrap > div:first-child {
  transform: translateY(-5px);
}

.mainCallToActionImgArea > div > div > a {
  display: flex;
  align-items: center;
}

.mainCallToActionImgArea > div > div:nth-child(2) > a > img {
  width: 30px;
}

.CartIcon {
  transform: translateX(-2px);
}

.mainEventChainWraper {
  width: 100%;
  height: 460px;
  overflow: hidden;
}

.mainCallToActionTitleGrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-left: 20px;
}

.mainCallToActionTitleGrid > div:first-child {
  height: 230px;
}

.mainCallToActionFooter {
  width: 100%;
  height: 200px;
  position: relative;
}
.mainCallToActionFooterLeft {
  position: absolute;
  bottom: 40px;
  left: 0px;
}

.mainCallToActionFooterRight {
  position: absolute;
  bottom: 40px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.younghondoshimLogo {
  width: 170px;
  object-fit: cover;
}
.mainCallToActtionFooterArrowUp {
  left: 20px;
  display: inline;
  width: 40px;
}
.ArrowUp {
  width: 40px;
  object-fit: cover;
  margin-top: 10px;
  opacity: 0.8;
  transition: all 0.3s ease;
  cursor: pointer;
}
.ArrowUp:hover {
  opacity: 1;
  transition: all 0.3s ease;
}

.mainCallToActionEmail {
  font-family: "IBMPlexSansKr";
  opacity: 0.6;
}

.mainCallToActionEmail:nth-child(2) {
  font-family: "Typewcond";
}

.mainCallToActionImgAreaWrap {
  font-family: "Typewcond";
}

.mainCallToActionImgAreaWrap > div {
  font-size: 40px;
}

.mainCallToActionImgAreaWrap:hover {
  opacity: 1;
  transition: all 0.3s ease;
}

.mainCallToActionImgArea > div {
  margin-left: 0px;
}

.mainCallToActionFooterLeft {
  left: 0px;
  bottom: 40px;
}

.mainCallToActionFooterRight {
  right: 0px;
  bottom: 40px;
}

@media (max-width: 1199px) {
  .mainCallToActionTitle {
    padding-left: 10px;
  }
  .mainCallToActionTitleGrid {
    height: 100%;
  }
}

@media (max-width: 849px) {
  .mainCallToAction {
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
    box-sizing: border-box;
    min-height: 1px;
    grid-template-columns: 1fr;
    transform: translateY(100px);
    max-height: 750px;
  }

  .mainCallToAction > div:first-child {
    display: none;
  }
  .mainCallToActionTitle {
    font-size: 11vw;
    padding: 0px;
    margin-bottom: 20px;
    transform: translateX(-8px) scaleY(1.2);
  }

  .mainCallToActionTextArea {
    font-size: max(25px, 4.5vw);
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    padding-right: 20px;
  }
  .mainCallToActionTextArea > div {
    display: block;
  }

  .mainCallToActionTitleGrid {
    margin-left: 0px;
  }
}
@media (max-width: 620px) {
  .mainCallToActionImgAreaWrap > div {
    font-size: 30px;
  }

  .younghondoshimLogo {
    width: 80px;
  }

  .mainCallToActionTitle {
    transform: scaleY(1.2) translateX(-3px);
  }
  .mainCallToAction {
    height: 80svh;
  }
}

@media (max-width: 499px) {
  .mainCallToActionImgAreaWrap > div {
    font-size: 25px;
  }

  .mainCallToActionImgArea > div {
    height: 30px;
    line-height: 30px;
  }

  .mainCallToAction {
    height: 70svh;
  }
  .mainCallToActionImgArea {
    margin-top: 170px;
  }
  .mainCallToActionBox {
    transform: translateY(50px);
  }
  .mainCallToAction {
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    box-sizing: border-box;
    min-height: 1px;
    grid-template-columns: 1fr;
  }

  .mainCallToAction > div:first-child {
    display: none;
  }
  .mainCallToActionTitle {
    font-size: 11vw;
    padding: 0px;
  }

  .mainCallToActionTextArea {
    font-size: 16px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    padding-right: 20px;
    font-weight: 400;
  }
  .mainCallToActionImgArea > div {
    margin-left: 0px;
    width: 180px;
  }
  .mainCallToActionTextArea > div {
    display: inline;
    margin-right: 10px;
  }
  .mainCallToActionFooterLeft {
    left: 0px;
    bottom: 40px;
  }
  .mainCallToActionFooterRight {
    right: 0px;
    bottom: 40px;
  }
  .mainCallToActionEmail {
    display: none;
  }
}
