body {
  background-color: #f3f1ee;
}

body::-webkit-scrollbar {
  width: 8px; /* 스크롤바의 너비 */
}

body::-webkit-scrollbar-track {
  background: #f3f1ee; /* 스크롤바 트랙의 배경색 */
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #04260c99; /* 스크롤바의 색상 */
  border-radius: 10px;
}

a:active,
a:focus,
a:hover,
a:visited,
a {
  text-decoration: none;
  color: #04260c;
}
