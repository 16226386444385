.fade-in-section {
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.3s ease-out;
}

.fade-in-sectionIs-visible {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.3s ease-out;
}

.is-visible {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.3s ease-out;
}
