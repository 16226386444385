.mainTitlewrap {
  display: grid;
  grid-template-columns: 1fr 11fr;
  height: 100svh;
  min-height: 900px;
  width: 100%;
  color: #04260c;
  position: relative;
  z-index: 1;
  margin-bottom: 20svh;
}

.mainTitleFlex {
  display: flex;
  align-items: center;
}

.mainTitleBox {
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(25px);
}

.mainTitleUpperText {
  font-family: "IBMPlexSansKr";
  font-weight: 700;
  transform: translateY(1vw);
  font-size: 20px;
  opacity: 0.8;
  margin-bottom: 1vw;
}

.mainTileMiddleText1 > div:first-child {
  margin-right: 1.9vw;
}

.younghondoshimLogoGreen {
  width: 9vw;
  min-width: 140px;
  object-fit: cover;
  margin-top: 10px;
  transform: translateX(-2px);
}

.mainTitleLowerText3 {
  margin-top: 5px;
  position: relative;
  height: 25px;
  display: flex;
  flex-wrap: wrap;
}

.mainTileMiddleText {
  font-size: max(90px, 11.4vw);
  font-family: "gravesend-sans";
  font-weight: 700;
  line-height: 8.5vw;
  transform: scaleY(1.5) translateX(-1vw);
  padding: 3.5vw 0px;
}

.mainTitleLowerText {
  margin-top: 1vw;
}

.mainTitleLowerText3 > a > img {
  width: 25px;
  margin-right: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  opacity: 0.6;
}
.mainTitleLowerText3 > a:nth-child(2) > img {
  padding-top: 3px;
}

.mainTitleLowerText3 > a > img:hover {
  transition: all 0.3s ease;
  opacity: 1;
}

.mainTitleLowerText1 {
  font-size: 20px;
  font-family: "Typewcond";
  font-weight: 700;
  opacity: 0.8;
}

.mainTitleLowerText2 {
  opacity: 0.8;
}

.mainTileMiddleText1 {
  letter-spacing: -0.01em;
}

.mainTileMiddleText1 > div,
.mainTileMiddleText2 > div {
  display: inline-block;
}

.mainTileMiddleText2 {
  letter-spacing: 0.265em;
  line-height: 0vw;
  padding-left: 0.5vw;
}

.mainTitleShow1 {
  display: block;
}

.mainTitleShow2 {
  display: none;
}

@media (max-width: 1199px) {
  .mainTileMiddleText1 > div,
  .mainTileMiddleText2 > div {
    display: block;
  }

  .mainTitleUpperText {
    margin-bottom: 1.7vw;
  }

  .mainTileMiddleText {
    font-size: max(55px, 11.6vw);
    line-height: 8vw;
    transform: scaleY(1.4) translateX(-1vw);
    padding: 1.5vw 0px;
  }

  .mainTileMiddleText2 {
    letter-spacing: 0.268em;
    line-height: 0vw;
    padding-left: 0.5vw;
  }

  .mainTitleUpperText {
    transform: translateY(0.5vw);
  }
  .mainTitleBox {
    transform: translateY(-40px);
  }

  .mainTitleLowerText1 {
    margin-top: 2vw;
  }
}

@media (max-width: 849px) {
  .mainTitleShow1 {
    display: none;
  }

  .mainTitleShow2 {
    display: block;
  }
  .mainTileMiddleText1 {
    letter-spacing: 0.01em;
  }

  .mainTitleShow2 > .mainTileMiddleText1 {
    padding: 8vw 0px 3.5vw;
    transform: scaleY(1);
    font-size: max(65px, 23.5vw);
    line-height: 18vw;
    transform: translateX(-1.2vw);
  }

  .mainTitleShow2 > .mainTileMiddleText1 > div > div:first-child {
    letter-spacing: 0.07em;
  }

  .mainTitleShow2 > .mainTileMiddleText2 > div {
    display: inline;
    font-size: max(30px, 9.5vw);
    letter-spacing: 0.02em;
  }

  .mainTitleShow2 > .mainTileMiddleText2 > div {
    line-height: 4.5vw;
  }

  .mainTitleLowerText {
    margin-top: 2vw;
  }

  .mainTileMiddleText1 > div,
  .mainTileMiddleText2 > div {
    display: block;
  }

  .mainTitlewrap {
    display: grid;
    grid-template-columns: 12fr;
    min-height: 0;
  }

  .mainTitleLowerText1 {
    display: none;
  }

  .mainTitlewrap > div:first-child {
    height: 0px;
    display: none;
  }
  .mainTitleBox {
    transform: translateY(0px);
    margin-left: 40px;
    padding: 0px;
  }

  .mainTitleLowerText2 {
    margin-top: 16vw;
  }

  .mainTitleUpperText {
    font-family: "IBMPlexSansKr";

    transform: translateY(5.5vw);
  }

  .mainTitleLowerText3 > a > img {
    width: 25px;
    margin-right: 20px;
    margin-top: 5px;
  }
}

@media (max-width: 499px) {
  .mainTileMiddleText {
    padding: 5vw 0px;
    transform: scaleY(1.2);
    transform: translateX(-1.2vw);
    font-size: max(55px, 17vw);
    line-height: 14vw;
  }

  .mainTitleShow2 > .mainTileMiddleText1 {
    font-size: max(55px, 21vw);
  }

  .mainTitleShow2 > .mainTileMiddleText1 > div > div:first-child {
    letter-spacing: -0.05em;
  }

  .mainTitleShow2 > .mainTileMiddleText1 {
    padding-top: 0px;
  }

  .mainTileMiddleText1 > div,
  .mainTileMiddleText2 > div {
    display: block;
  }

  .mainTitlewrap {
    display: grid;
    grid-template-columns: 12fr;
    min-height: 0;
  }

  .mainTitleShow2 > .mainTileMiddleText2 > div {
    display: none;
    font-size: max(25px, 7.2vw) !important;
  }

  .mainTitlewrap > div:first-child {
    height: 0px;
    display: none;
  }
  .mainTitleBox {
    transform: translateY(0px);
    margin-left: 40px;
    padding: 0px;
  }

  .mainTileMiddleText1 {
    letter-spacing: -0.08em;
  }

  .mainTileMiddleText2 {
    letter-spacing: -0.1em;
  }

  .mainTitleLowerText2 {
    margin-top: 40px;
  }

  .mainTitleUpperText {
    font-family: "IBMPlexSansKr";

    transform: translateY(6vw);
  }

  .mainTitleLowerText3 > a > img {
    width: 20px;
    margin-right: 20px;
    margin-top: 5px;
  }
}
