.mainIntroduceWrap {
  display: grid;
  grid-template-columns: 4fr 5fr 1fr;
  grid-gap: 20px;
  height: 55svh;
  min-height: 750px;
  width: 100%;
  color: #04260c;
  position: relative;
  z-index: 1;
}

.mainIntroduceFlex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainIntroduceText {
  width: 860px;
}

.mainIntroduceUpperText {
  font-family: "Typewcond";
  opacity: 0.8;
  margin-bottom: 5px;
  height: 22px;
  transition: all 0.6s;
}

.mainIntroduceMiddleText {
  font-family: "IBMPlexSansKr";
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -2px;
  margin-bottom: 20px;
  transition: all 0.6s;
}

.mainIntroduceMiddleText > div:first-child {
  letter-spacing: -0.02em;
}

.mainIntroduceMiddleText > div:nth-child(2) {
  letter-spacing: 0.003em;
}

.mainIntroduceLowerText {
  font-family: "IBMPlexSansKr";
  opacity: 0.8;
  letter-spacing: -0.1px;
  line-height: 140%;
  transition: all 0.6s;
}

.mainIntroduceLowerText > div:nth-child(2) {
  letter-spacing: 0.0445em;
}

.mainIntroduceShow1 {
  display: block;
}

.mainIntroduceShow2 {
  display: none;
}

.mainIntroduceShow3 {
  display: none;
}

.mainIntroduceShow4 {
  display: none;
}

.mainCallToActionEmail > span:first-child {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .mainIntroduceWrap {
    grid-template-columns: 4fr 6fr 1fr;
  }
  .mainIntroduceWrap {
    height: 85svh;
  }

  .mainIntroduceShow2 {
    display: block;
  }
  .mainIntroduceShow1 {
    display: none;
  }

  .mainIntroduceShow3 {
    display: none;
  }
  .mainIntroduceText {
    width: 675px;
  }

  .mainIntroduceMiddleText > div:first-child {
    letter-spacing: 0em;
  }

  .mainIntroduceMiddleText > div:nth-child(2) {
    letter-spacing: -0.045em;
  }

  .mainIntroduceMiddleText > div:nth-child(3) {
    letter-spacing: -0.105em;
  }

  .mainIntroduceLowerText {
    line-height: 150%;
  }

  .mainIntroduceLowerText > div:first-child {
    letter-spacing: 0.015em;
  }

  .mainIntroduceLowerText > div:nth-child(2) {
    letter-spacing: -0.029em;
  }

  .mainIntroduceLowerText > div:nth-child(3) {
    letter-spacing: 0.021em;
  }
}

@media (max-width: 849px) {
  .mainIntroduceShow1 {
    display: none;
  }

  .mainIntroduceShow2 {
    display: none;
  }

  .mainIntroduceShow3 {
    display: block;
  }

  .mainIntroduceWrap.mainIntroduceShow3 > div {
    line-height: 125%;
  }

  .mainIntroduceMiddleText > div:first-child {
    letter-spacing: 0.02em;
  }

  .mainIntroduceMiddleText > div:nth-child(2) {
    letter-spacing: -0.045em;
  }

  .mainIntroduceMiddleText > div:nth-child(3) {
    letter-spacing: -0.115em;
  }

  .mainIntroduceMiddleText > div:nth-child(4) {
    letter-spacing: -0.115em;
  }

  .mainIntroduceLowerText > div:first-child {
    letter-spacing: -0.045em;
  }

  .mainIntroduceLowerText > div:nth-child(2) {
    letter-spacing: -0.029em;
  }

  .mainIntroduceLowerText > div:nth-child(3) {
    letter-spacing: 0.05em;
  }

  .mainIntroduceLowerText > div:nth-child(4) {
    letter-spacing: 0.001em;
  }

  .mainIntroduceShow3.mainIntroduceLowerText {
    margin-top: 30px;
  }

  .mainIntroduceFlex {
    margin-right: 40px;
  }
  .mainIntroduceWrap {
    grid-template-columns: 4fr 8fr;
    min-height: 1px;
  }

  .mainIntroduceLowerText.mainIntroduceShow3 {
    line-height: 150%;
  }

  .mainIntroduceText {
    margin: 0px 0px;
    width: 495px;
  }

  .mainIntroduceWrap {
    height: 85svh;
  }
}

@media (max-width: 620px) {
  .mainCallToAction {
    height: 70svh;
  }

  .mainIntroduceFlex {
    width: 100%;
  }

  .mainIntroduceMiddleText.mainIntroduceShow3 {
    font-size: 30px;
  }
  .mainIntroduceLowerText.mainIntroduceShow3 > div {
    display: inline;
    margin-right: 8px;
  }

  .mainIntroduceText {
    margin: 0px 0px;
    width: 375px;
    margin-left: 20px;
  }

  .mainIntroduceFlex {
    margin-left: 0px;
    padding-left: 20px;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  .mainIntroduceWrap {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    min-height: 1px;
  }

  .mainIntroduceFlex {
    justify-content: flex-start;
  }
}

@media (max-width: 499px) {
  .mainIntroduceFlex {
    justify-content: center;
  }

  .mainIntroduceShow3 {
    display: none;
  }

  .mainIntroduceShow4 {
    display: block;
    text-align: justify;
  }

  .mainIntroduceFlex {
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 10px;
  }
  .mainIntroduceWrap {
    grid-template-columns: 12fr;
    min-height: 1px;
  }

  .mainIntroduceMiddleText.mainIntroduceShow3 > div {
    display: inline;
    letter-spacing: -0.05em;
  }
  .mainIntroduceLowerText.mainIntroduceShow3 > div {
    letter-spacing: -0.05em;
  }

  .mainIntroduceText {
    margin: 0px 0px;
    width: calc(100% - 80px);
    margin-left: 0px;
    margin-right: 20px;
  }

  .mainCallToActionTitleGrid > div:first-child {
    height: 180px !important;
  }

  .mainIntroduceWrap > div:first-child {
    height: 0px;
    display: none;
  }

  .mainIntroduceMiddleText {
    font-size: 25px;
  }

  .mainCallToActionFooter {
    height: 120px !important;
  }
}
