.loadingWrap {
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  width: 100%;
  height: 100%;
  background-color: #f3f1ee;
  z-index: 100;
  align-items: center;
  justify-content: center;
}

.loadingWrap > div:first-child {
  transform: translateY(80px);
}
.loadingText {
  font-size: 40px;
  font-family: "Typewcond";
  color: #04260c;
  position: relative;

  text-align: center;
  display: inline;
  transition: all 1s;
}

.loadingText > div {
  display: inline;
  margin-right: 20px;
}

.loadingText:last-child > div {
  margin-right: 0px;
}

.loadingTextAreaWidth {
  width: 436px;
  height: 2px;
  background-color: #f3f1ee;
  overflow: hidden;
  padding-top: 70px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 20px;
}

.loadingTextAreaWrap {
  position: absolute;
  width: 100%;
  z-index: 10;
  text-align: center;
  left: 50%;
  transform: translateX(-50%) translateY(-70px);
}

.loadingTextWrap1 {
  display: inline-block;
  margin-right: 20px;
  width: 126px;
}

.loadingTextWrap2 {
  display: inline-block;
  margin-right: 20px;
  width: 36px;
}

.loadingTextWrap3 {
  display: inline-block;
  margin-right: 20px;
  width: 54px;
}

.loadingTextWrap4 {
  display: inline-block;
  width: 160px;
}

.loadingText.visible {
  visibility: visible;
}

@media (max-width: 1499px) {
  .loadingWrap {
    grid-template-columns: 3fr 6fr 3fr;
  }
}

@media (max-width: 915px) {
  .loadingText {
    font-size: 30px;
  }

  .loadingTextAreaWrap {
    transform: translateX(-50%) translateY(-55px);
  }

  .loadingTextAreaWidth {
    width: 328px;
    padding-bottom: 20px;
  }

  .loadingTextWrap1 {
    display: inline-block;
    margin-right: 15px;
    width: 95px;
  }

  .loadingTextWrap2 {
    display: inline-block;
    margin-right: 15px;
    width: 27px;
  }

  .loadingTextWrap3 {
    display: inline-block;
    width: 41px;
    margin-right: 15px;
  }
  .loadingTextWrap4 {
    display: inline-block;
    width: 120px;
  }
}

@media (max-width: 691px) {
  .loadingWrap {
    grid-template-columns: 2fr 8fr 2fr;
  }
  .loadingTextAreaWrap {
    text-align: center;
    transform: translateX(-50%) translateY(-40px);
  }
  .loadingText {
    font-size: 22px;
  }

  .loadingTextAreaWidth {
    width: 243px;
    padding-bottom: 70px;
  }

  .loadingTextWrap1 {
    display: inline-block;
    margin-right: 11px;
    width: 70px;
  }

  .loadingTextWrap2 {
    display: inline-block;
    margin-right: 11px;
    width: 20px;
  }

  .loadingTextWrap3 {
    display: inline-block;
    margin-right: 11px;
    width: 30px;
  }

  .loadingTextWrap4 {
    display: inline-block;
    width: 90px;
  }
}
