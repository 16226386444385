.Size-Up-section {
  opacity: 0;
  margin-left: 0vw;
}

.Size-Up-sectionis-Size-Up {
  opacity: 1;
  margin-left: 0;
}

.is-Size-Up {
  opacity: 1;
  margin-left: 0;
}
